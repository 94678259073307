
import store from '@/store';
import { Options, Vue } from 'vue-class-component';
import MiniSpinner from './miniSpinner.vue';

@Options({
    components: {
        MiniSpinner
    }
})
export default class SpinnerContainer extends Vue {

    get showSpinner(){
        return store.state.showSpinner > 0;
    }
}
