import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "body",
  class: "body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_modals_container = _resolveComponent("modals-container")!
  const _component_spinner_container = _resolveComponent("spinner-container")!
  const _component_pop_up_tx = _resolveComponent("pop-up-tx")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view),
    _createVNode(_component_modals_container),
    _createVNode(_component_spinner_container),
    _createVNode(_component_pop_up_tx)
  ]))
}