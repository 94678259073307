import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import modalPlugin from './plugins/modalPlugin';
import FormField from './components/formFieldPlugin/formField.vue';
import FTS from '@/filters';

import 'bootstrap/dist/css/bootstrap.css';
import '@/assets/css/style.css';
import '@/assets/css/fonts/Gilroy.css';


const app = createApp(App)
    .use(store)
    .use(router)
    .use(modalPlugin);

    app.config.globalProperties.$filters = FTS;
app.mount('#app');
