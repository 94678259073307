import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6141637c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onMousedown", "onMouseup", "onTouchstart", "onTouchend"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["__modalsContainer", { '__show': _ctx.items.length > 0 || _ctx.transitions > 0 }])
  }, [
    _createVNode(_TransitionGroup, {
      name: "pop",
      onBeforeEnter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.transitions ++)),
      onAfterLeave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.transitions --))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "__modalBg",
            key: i,
            ref_for: true,
            ref: '__modalBg' + i,
            onMousedown: ($event: any) => (_ctx.onDownBg($event, i)),
            onMouseup: ($event: any) => (_ctx.onUpBg($event, i)),
            onTouchstart: ($event: any) => (_ctx.onDownBg($event, i)),
            onTouchend: ($event: any) => (_ctx.onUpBg($event, i))
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(item.modalComponent), _mergeProps(item.props, {
              onClose: ($event: any) => (_ctx.closeModal(i))
            }), null, 16, ["onClose"]))
          ], 40, _hoisted_1))
        }), 128))
      ]),
      _: 1
    })
  ], 2))
}