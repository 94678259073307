

import { Options, Vue } from 'vue-class-component';
import store from './store';
import { Watch } from 'vue-property-decorator';
import SpinnerContainer from '@/components/spinnerContainer.vue';
import { StorageServices } from './services/StorageServices';
import { BlockchainService } from '@/services/BlockchainService';
import popUpTx from './components/popUpTx.vue';

@Options({
    components: {
        SpinnerContainer,
        popUpTx
    }
})
export default class App extends Vue {



    async created(){


    }
    
    async mounted(){

        if (typeof window.ethereum === 'undefined') {
            return;
        } else{

            
        //nell eventualita che un utente si disconnetta o cambi account senza disconnettersi stiamo in ascolto su accountsChanged
        window.ethereum.on('accountsChanged', (accounts) => {
            if (accounts.length == 0) {
                    //se si disconnette svuotiamo lo store 
                    StorageServices.setWalletAddress(undefined);
                    StorageServices.setBalanceAddress(undefined)
                } else {
                    //se cambia account chiamiamo connectingMetaMask che consulta l'attuale 
                    //signer e modifica i valori nello store  modifica nello store, se l' utente cambia da connesso la tendina non riapre
                    this.getLastAddressAndBalance();
                    window.location.reload()
                    return
                }
                });
            }
        }

        async getLastAddressAndBalance(){
            await BlockchainService.connectingMetaMask();
        }

    @Watch("$store.state.showSpinner")
    disableScroll(){
        var el = document.getElementById("body");

        if(el == null)
            return;

        if(store.state.showSpinner > 0)
            el.classList.add("overflow-hidden");
        else
            el.classList.remove("overflow-hidden");
    }

}

