import { createStore } from 'vuex';

export default createStore({
  state: {
    walletAddress: "",
    showSpinner: 0,
    spinnerMessage: "",
    balanceAdress: "",
    popUpMessage: "",
    popUpClosable: false,
    popUpIsOpen: false,
    modalView: false
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
