import store from '@/store';
import cloneDeep from 'lodash.clonedeep';

class _StorageServices {

    private readFromLocalStorage<T>(lsName: string): T | null {
        let data = window.localStorage.getItem(lsName);
        if(data){
            return JSON.parse(data);
        } else {
            return null;
        }
    }

    getWalletAddress() : string {
        return this.readFromLocalStorage<string>('walletAddress');
    }

    setWalletAddress(walletAddress: string) {
        window.localStorage.setItem('walletAddress', JSON.stringify(walletAddress));
        store.state.walletAddress = walletAddress;
    }

    getBalanceAddress() : string {
        return this.readFromLocalStorage<string>('balanceAdress');
    }

    setBalanceAddress(balanceAdress: string) {
        window.localStorage.setItem('balanceAdress', JSON.stringify(balanceAdress));
        store.state.balanceAdress = balanceAdress;
    }
    
}

export let StorageServices = new _StorageServices();