
import { Options, Vue } from 'vue-class-component';
import store from '@/store';


@Options({})
export default class MiniSpinner extends Vue {

    get message(){
        return store.state.spinnerMessage
    }
    

    mounted() {
    }

}
