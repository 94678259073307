import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/',
            name: 'dashboard',
            component: () => import("./views/dashboard.vue")
        }
    ];
};