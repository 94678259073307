export let baseUrl = process.env.VUE_APP_ENDPOINT;
export let siteUrl = process.env.VUE_APP_WEBSITE;
export let wsBaseUrl = process.env.VUE_APP_WS_URL;
export let moralisUrl = process.env.VUE_APP_MORALIS_SERVER_URL
export let moralisApplicationID = process.env.VUE_APP_MORALISAPPLICATIONID;
export let MoralisMasterKey = process.env.VUE_APP_MORALISMASTERKEY;

// contracts and chain id 

export let deployerEnv = process.env.VUE_APP_ADDRESS_DEPLOYER;

export let erc20ContractAddressEnv = process.env.VUE_APP_ADDRESS_ERC20;
export let timeLockContractAddressEnv = process.env.VUE_APP_ADDRESS_TIMELOCK;
export let merlinGovernorAddressEnv = process.env.VUE_APP_ADDRESS_GOVERNOR;

export let networkVersionEnv = process.env.VUE_APP_NETWORKVERSION;

export let chainIdEnv = process.env.VUE_APP_CHAINID;
export let chainNameEnv = process.env.VUE_APP_CHAIN_NAME;

export let nativeCurrencyNameEnv = process.env.VUE_APP_CURRENCY_NAME;
export let nativeCurrencyDecimalsEnv = parseInt(process.env.VUE_APP_CURRENCY_DECIMALS);
export let nativeCurrencySymbolEnv = process.env.VUE_APP_CURRENCY_SYMBOL;

export let rpcUrlsEnv: string[] = JSON.parse(process.env.VUE_APP_CHAIN_RPC_URLS)
export let blockExplorerUrlsEnv: string[] = JSON.parse(process.env.VUE_APP_CHAIN_EXPLORER_URLS)

