import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router';
import { dashboardModule } from '@/views/dashboard';
import { proposalsModule } from '@/views/proposals';
import { leaderboardModule } from '@/views/leaderboard';
import { userDetailModule } from '@/views/userDetail';
import { proposalDetailModule } from '@/views/proposalDetail';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import("../views/layout.vue"),
    children: [
        ...dashboardModule.routes(),
        ...proposalsModule.routes(),
        ...leaderboardModule.routes(),
        ...userDetailModule.routes(),
        ...proposalDetailModule.routes()
        
    ]  
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    window.scrollTo( {
      left: savedPosition ? savedPosition.left : 0, 
      top: savedPosition ? savedPosition.top : 0,
      behavior: <any>'instant'
    });
}
})

export default router
