import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/userDetail/:rank/:userAddress', 
            name: 'userDetail',
            component: () => import("./views/userDetail.vue")
        }
    ];
};