import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/mini_loader.gif'
import _imports_1 from '@/assets/img/close_icon.svg'


const _withScopeId = n => (_pushScopeId("data-v-94ee0640"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-content-center align-items-center h-100 w-100" }
const _hoisted_2 = { class: "f_blue f_16 f_semiBold text-capitalize me-2" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  alt: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["popUp", _ctx.popUpIsOpen ? 'opened' : 'closed'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass([!_ctx.popUpClosable ? 'justify-content-around' : 'justify-content-center', "d-flex w-100 align-items-center"])
      }, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.popUpMessage), 1),
        (!_ctx.popUpClosable)
          ? (_openBlock(), _createElementBlock("img", _hoisted_3))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _createElementVNode("img", {
      class: _normalizeClass([_ctx.popUpClosable ? 'd-block' : 'd-none', "closeIcon pointer"]),
      src: _imports_1,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closePop()))
    }, null, 2)
  ], 2))
}