import { ethers} from "ethers";
import { CommonServices } from "./CommonServices";
import { StorageServices } from "./StorageServices";
import { deployerEnv, erc20ContractAddressEnv,networkVersionEnv,  timeLockContractAddressEnv, merlinGovernorAddressEnv, chainIdEnv, chainNameEnv, nativeCurrencyNameEnv, nativeCurrencyDecimalsEnv, nativeCurrencySymbolEnv, rpcUrlsEnv, blockExplorerUrlsEnv } from "@/config";


class _BlockchainService {
    //Contract
    merlinGovernor: any;
    //signer
    signer: ethers.providers.JsonRpcSigner;

    //connect metamask return signer
    async connectingMetaMask() {
        //se non è presente metamask nel browser 
        if (typeof window.ethereum === 'undefined') {
            return null;
        }

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        //switch sulla chain corretta 
        await this.switchCorrectChain()
        //controllo sulla chain per selezionare quella giusta
        try {
            // Inizia la connessione con MetaMask
            await provider.send("eth_requestAccounts", []);
            
        } catch (error) {
            // MetaMask non è installato o non è stato accettato
            console.error(error + '    non hai metamask ');
            return null;
        }
        // Crea un oggetto Signer utilizzando il provider
        this.signer = await provider.getSigner();
        // Recupera l'indirizzo del portafoglio
        let address = await this.signer.getAddress();
        // Salva l'indirizzo del portafoglio nello storage locale
        await StorageServices.setWalletAddress(address);
        // Salva il balance in store
        var balance = await this.getErc20Balance();
        await StorageServices.setBalanceAddress(balance)
        return this.signer;
    }

    //switch mumbai network
    async switchCorrectChain() {
        var networkVersion = window.ethereum.networkVersion;
    
        if (networkVersion === networkVersionEnv) {
            return false;
        }
    
        if (!window.ethereum) {
            alert("Metamask not found!");
            return false;
        }
    
        const chainId = chainIdEnv; // Chain ID per Polygon Mumbai come stringa esadecimale da env
    
        if (window.ethereum.networkVersion !== chainId) {
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: chainId }],
                });
            } catch (err) {
                if (err.code === 4902) {
                    try {
                        await window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainName: chainNameEnv,
                                    chainId: chainId,
                                    nativeCurrency: { name: nativeCurrencyNameEnv, decimals: nativeCurrencyDecimalsEnv, symbol: nativeCurrencySymbolEnv },
                                    rpcUrls: rpcUrlsEnv,
                                    blockExplorerUrls: blockExplorerUrlsEnv,
                                },
                            ],
                        });
                    } catch (err) {
                        console.error(err);
                        return false;
                    }
                } else {
                    console.error(err);
                    return false;
                }
    
                networkVersion = window.ethereum.networkVersion;
                this.connectingMetaMask();
            }
        }
    } 

    //try to autoconnect per avere sempre un signer sia in cambio account che al refresh della pagina, chiama in app 
    async tryToAutoConnect() {

        let signer = await this.connectingMetaMask();
        if (await signer.getAddress()) {
            try {

                let address = await signer.getAddress();
                this.signer = signer;
                await StorageServices.setWalletAddress(address);
                
            } catch (error) {
                this.signer = null;
                // chiamiamo la funzione per ottenere il signer e assegniamo il valore alla variabile this.signer
                this.signer = await this.connectingMetaMask();
                let address = await this.signer.getAddress();
                await StorageServices.setWalletAddress(address);
            }
        } else {
            
            // chiamiamo la funzione per ottenere il signer e assegniamo il valore alla variabile this.signer
            this.signer = await this.connectingMetaMask();
            let address = await this.signer.getAddress();
            await StorageServices.setWalletAddress(address);
            
        }
        
    }

    //andiamo a ptenderci il balance sempre aggiornato per la navbar per evitare chiamata al server 
    //lo settiamo in local storage e la richiamiamo ad ogni cambio account e connessione 
    async getErc20Balance() {
        var erc20ABI = require("./ABI/merlinERC20.json"); 
        var contract = await this.setContract(erc20ContractAddressEnv, erc20ABI);
        var balance = await contract.balanceOf(await this.signer.getAddress());
        return balance._hex;
    }

    // chiamata di setcontract generale
    async setContract(contractAddress: string, contractABI: any){
        let jsonInterface = JSON.parse(contractABI.result);
        const contract = new ethers.Contract(contractAddress, jsonInterface, this.signer);
        return contract;
    }

    async setGovernorContract(){
        var merlinGovernorABI = require("@/services/ABI/merlinGovernorABI.json");
        // Parse the ABI string into a JSON array
        merlinGovernorABI = JSON.parse(merlinGovernorABI.result);
        this.merlinGovernor = new ethers.Contract(merlinGovernorAddressEnv, merlinGovernorABI, this.signer);
        return this.merlinGovernor;
    }


    //l' id della proposta è calcolato dall' hash della descrizione
    //proposta la propose la fanno sole dei address specifici 
    async propose(contractAddress: string, func: string, value: any[], proposalDescription: string)
    {
        //Codifichiamo la proposal. Func = funzione da chiamare del contract (ex: transfer). //value = nuovi valori da passare (da vedere come è meglio passare questi valori)
        var abi = "https://api-testnet.polygonscan.com/api?module=contract&action=getabi&address=" + contractAddress; //PESSIMA SOLUZIONE. dA MODIFICARE
        var contract = await this.setContract(contractAddress, abi);
        const encodedFunctionCall = contract.interface.encodeFunctionData(func, value);

        //passiamo la propose al governor (set governo prima di tutto, appena ci si collega con metamask)
        const proposeTx = await this.merlinGovernor.propose(
            [contractAddress],
            [0],
            [encodedFunctionCall],
            proposalDescription
        )

        const proposeReceipt = await proposeTx.wait(1);
        return proposeReceipt.events[0];
    }

    // 3 chiamate per le gestione della delegazione 
    async delegate(delegateAddress: string)
    {
        var erc20ABI = require("./ABI/merlinERC20.json"); 
        var contract = await this.setContract(erc20ContractAddressEnv, erc20ABI);

        var delegateTx = await contract.delegate(delegateAddress);
        CommonServices.openPopUp("pending...", false);
        var ris = await this.controlTxPopUp(delegateTx.hash);

        return delegateTx;
    }

    async delegateToYourself()
    {
        var erc20ABI = require("./ABI/merlinERC20.json"); 
        var contract = await this.setContract(erc20ContractAddressEnv, erc20ABI);

        var delegateTx = await contract.delegate(await this.signer.getAddress());
        CommonServices.openPopUp("pending...", false);
        var ris = await this.controlTxPopUp(delegateTx.hash);

        return delegateTx;
    }

    async removeDelegation(){
        var erc20ABI = require("./ABI/merlinERC20.json"); 
        var contract = await this.setContract(erc20ContractAddressEnv, erc20ABI);

        var delegateTx = await contract.delegate('0x0000000000000000000000000000000000000000');
        CommonServices.openPopUp("pending...", false);
        var ris = await this.controlTxPopUp(delegateTx.hash);

        return delegateTx;
    }

    // chiamata pop up che rimane in ascolto sulle tx
    async controlTxPopUp(tx: string){
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        var ris = await provider.getTransactionReceipt(tx);
        if(ris!=null){
            if(ris.status==0){
                await CommonServices.closePopUp();
                await CommonServices.openPopUp('transaction failed!' , true)
                
                
                return 0;
            }
            if(ris.status==1)
            {   
                await CommonServices.closePopUp();
                await CommonServices.openPopUp('transaction confirmed!' , true)
                
                
                return 1;
            }
        }
        return this.controlTxPopUp(tx);     
    }

    //total supply
    async getTotalSupply()
    {
        var erc20ABI = require("./ABI/merlinERC20.json"); 
        var contract = await this.setContract(erc20ContractAddressEnv, erc20ABI);
        var supply = await contract.totalSupply();
        return supply;
    }

    //andiamo da db 
    async getVotes(voterAddress: string, blockNumber: number)
    {
        var bigBlockNumber = BigInt(blockNumber);
        var votes = Number(await this.merlinGovernor.getVotes(voterAddress, bigBlockNumber));

        return votes;
    }

    //semplice voto 
    async vote(proposalId: string, voteWay: Number, reason: string)
    {        
        var BigProposalId = BigInt(proposalId);
        await this.setGovernorContract()
        var voteTx = await this.merlinGovernor.castVoteWithReason(BigProposalId, voteWay, reason);
        CommonServices.openPopUp("pending...", false);
        var ris = await this.controlTxPopUp(voteTx.hash)

        return voteTx;
    }

    //timelock coda 
    async queue(proposalDescription: string, contractAddress: string, func: string, value: any[])
    {
        const descriptionHash = ethers.utils.id(proposalDescription);
        const encodedFunctionCall = this.merlinGovernor.interface.encodeFunctionData(func, value);
        const queueTx = await this.merlinGovernor.queue([contractAddress], [0], [encodedFunctionCall], descriptionHash);

        return queueTx;
    }

    async execute(proposalDescription: string, contractAddress: string, func: string, value: any[])
    {
        const descriptionHash = ethers.utils.id(proposalDescription);
        const encodedFunctionCall = this.merlinGovernor.interface.encodeFunctionData(func, value);
        const exTx = await this.merlinGovernor.execute([contractAddress], [0], [encodedFunctionCall], descriptionHash);

        return exTx;
    }


    //funzione che riporta il blocco x stima e stampa della data
    async getBlock(endBlockNumber){
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        var ris = await provider.getBlock(endBlockNumber);
        return ris;
    }

    async getBlockNumber(){
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        var ris = await provider.getBlockNumber();
        return ris 
    }

}

export let BlockchainService = new _BlockchainService();
