
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import store from '@/store';
import  {CommonServices}  from '@/services/CommonServices';

@Options({
    components: {
        
    }
})
export default class popUpTx extends Vue {

    get popUpMessage(){
        return store.state.popUpMessage;
    }
    
    get popUpClosable() {
        return store.state.popUpClosable;
    }

    get popUpIsOpen() {
        return store.state.popUpIsOpen;
    }


    created(){

    }


    closePop(){
        CommonServices.closePopUp()
    }
    
}
